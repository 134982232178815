import React, { useState, useContext, useEffect } from "react";
import { navigate, Link } from "gatsby";
import { AuthContext } from "../context/AuthContext";
import { LanguageContext } from "../context";
import Layout from "../components/layout";
import Button2 from "../components/button-generic";
import Seo from "../components/seo"; // Import Seo component
import "../assets/css/login.css"; // Custom styles
import unidaLogo from "../images/images-logos-unida-original@3x.png"; // Import the logo

const LoginPage = ({ location, pageContext }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const languageContext = useContext(LanguageContext);
  const { t } = languageContext || { t: (text) => text };

  const isBrowser = typeof window !== "undefined";
  const { login, loading } = useContext(AuthContext);

  const handleLogin = async (e) => {
    e.preventDefault();

    // Check honeypot value
    const honeypot = e.target.elements["honeypot"].value;
    if (honeypot) {
      setErrorMessage(t("Suspicious activity detected. Please try again."));
      return;
    }

    try {
      if (login) {
        await login(username, password);
      }
    } catch (error) {
      setErrorMessage(t(error.message));
    }
  };

  useEffect(() => {
    if (!isBrowser) return;
  }, []);

  return (
    <Layout location={location} langcode={pageContext?.langcode}>
      {/* Add the Seo component */}
      <Seo
        title={t("Login")} // Use a translated title
        lang={pageContext?.langcode || "en"}
        description={t("Login to access the Escola Bíblica Online platform")}
        image={unidaLogo} // Optional: Add a logo or specific image if available
        canonicalPath={`/login`} // Optional: Add a canonical path
      />
      {loading ? (
        <div>{t("Loading...")}</div> // Use translation for loading
      ) : (
        <div className="login-container">
          <Link to="/"><img src={unidaLogo} alt="Unida Logo" className="unida-logo" /></Link>

          <h1 className="H2-Medium-C---bordeaux">{t("Login")}</h1>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="username">{t("Username")}:</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">{t("Password")}:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            {/* Honeypot field */}
            <input
              type="text"
              name="honeypot"
              style={{ display: 'none' }}
              autoComplete="off"
            />

            <Button2 title={t("Log In")} type="submit" disabled={loading} />
          </form>
        </div>
      )}
    </Layout>
  );
};

export default LoginPage;
