import React, {useContext} from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Slider from "../components/slider"
import Ribbon from "../components/ribbon"
import HomeTopContent from "../components/home-top-content"
import MinistriesList from "../components/ministries/ministries-list"
import EventsList from "../components/events/events-list"
import BlogList from "../components/blog/blog-list"
import TeamList from "../components/team/team-list"
import Podcast from "../components/podcast"
import moment from "moment"
import Utils from "../utils"
import { LanguageContext } from "../context"

const check = moment();
//const day = check.format('dddd') // => ('Monday' , 'Tuesday' ----)
const month = check.format('MMMM') // => ('January','February.....)
//const year = check.format('YYYY') // => ('2012','2013' ...)

//console.log("HomePagePaths", homePagePaths);

const HomeTemplate = ({ data, location, pageContext }) => {
  const allNodeBlogPost = data?.allNodeBlogPost?.nodes
  const allNodeTeam = data?.allNodeTeam?.nodes
  const allNodePodcast = data?.podcasts
  const preachSeries = data?.preachSeries?.edges
  //console.log("PREACHING Series,", preachSeries)
  const slides = data?.slides

  const shuffledTeam = Utils.shuffleObjectsArray(allNodeTeam);

  const { t } = useContext(LanguageContext)

  const wrapSpecialWords = (text) => {
    // Ensure text is a string
    if (typeof text !== 'string') {
      console.warn('wrapSpecialWords expected a string, received:', text);
      return text; // Return text as-is or provide a fallback
    }
  const specialWords = ['pregària', 'oración', 'prayer'];
  const regex = new RegExp(`(${specialWords.join('|')})`, 'gi');

  return text.split(regex).map((part, index) =>
    specialWords.includes(part.toLowerCase())
      ? <span key={index} className="fill-background">{part}</span>
        : part
    );
  };

  return (
    <Layout
      location={location}
      title={translate(pageContext?.langcode, 'Home')}
      langcode={pageContext?.langcode}
      translationPaths={homePagePaths}
      className="page-home"
    >
      <Seo
        title={translate(pageContext?.langcode, 'Home')}
        lang={pageContext?.langcode}
        image={slides?.edges[0]?.node?.relationships?.field_image?.image_style_uri?._653_432}
        description={slides?.edges[0]?.node?.field_subtitle}
        location={location}
        canonicalPath={`/`}
      />
      <Header
        lang={pageContext?.langcode}
        location={location}
       />
      <div className="slider-container">
      <Slider
        lang={pageContext?.langcode}
        slides={slides}
        location={location}
       />
      <Ribbon
        lang={pageContext?.langcode}
        preachSeries={preachSeries}
        location={location}
       />
      </div>
      <HomeTopContent
        lang={pageContext?.langcode}
        //text={<span>Necessites <span className="fill-backgrond">pregària</span>?
        //      No importa al que t'enfrontis, ens encantaria pregar amb tu!</span>
        // }
         text={"Necessites pregària? No importa al que t'enfrontis, ens encantaria pregar amb tu!"}
         className="row home-top-content m-b-152"
         location={location}
       />
      <MinistriesList
        lang={pageContext?.langcode}
        location={location}
       />
      <EventsList
        title={"Next events"}
        month={month}
        lang={pageContext?.langcode}
        data={data?.events?.edges}
        className="page-home-events"
        location={location}
       />
       <BlogList
        title="Publicacions recents"
        blogPosts={allNodeBlogPost}
        lang={pageContext?.langcode}
        buttonTitle="Anar al Blog"
        buttonUrl="/blog"
        location={location}
       />
       <TeamList
        title="El nostre equip"
        teamList={shuffledTeam}
        lang={pageContext?.langcode}
        className="page-home-team"
        location={location}
        />
        <Podcast
          title="Podcast Setmanal"
          description="No perdis l’oportunitat d'escoltar tots els nostres sermons setmanals! Estem amb tu!"
          lang={pageContext?.langcode}
          podcasts={allNodePodcast}
          location={location}
        />


      <Footer
        lang={pageContext?.langcode}
        location={location}
       />
    </Layout>
  )
}

export default HomeTemplate




export const pageQuery = graphql`
  query PageById($langcode: String!, $currentDate: Date!) {
    allNodeBlogPost(
        limit: 3
        sort: {fields: created, order: DESC}
        filter: { langcode: { eq: $langcode } }
      ) {
      nodes {
        langcode
        title
        created(formatString: "DD MMMM YYYY", locale: $langcode)
        body {
          summary
        }
        path {
          alias
        }
        relationships {
          field_image {
            image_style_uri {
              large
              medium
              thumbnail
              wide
              _294x192
              _541x359
              _653_432
            }
          }
        }
      }
    }
    allNodeTeam(limit: 20, filter: { langcode: { eq: $langcode } }) {
      nodes {
        langcode
        title
        field_email
        field_role
        field_team_type
        relationships {
          field_image {
            image_style_uri {
              large
              medium
              thumbnail
              wide
              _294x192
              _541x359
              _653_432
            }
          }
        }
      }
    }
    form: webformWebform(drupal_internal__id: {eq: "contact"}) {
      drupal_internal__id
      langcode
      elements {
        name
        type
        attributes {
          name
          value
        }
      }
    }
    preachSeries: allNodeEvent(
      filter: {
        field_event_type: {eq: "Worship"}
        nextSunday: {eq: true}
        langcode: { eq: $langcode }
      }
    ) {
      edges {
        node {
          field_series_session_number
          field_event_type
          title
          langcode
          field_serie {
            drupal_internal__target_id
          }
          relationships {
            field_serie {
              field_serie_subtitle
              field_total_sessions
              name
            }
          }
          nextSunday
          field_start_time
        }
      }
    }
      slides: allNodeSlidder(
      filter: {langcode: {eq: $langcode}, status: {eq: true}}
    )
    {
      edges {
        node {
          id
          langcode
          title
          field_cta {
            title
            uri
          }
          field_image {
            title
          }
          field_subtitle
          relationships {
            field_image {
              image_style_uri {
                large
                medium
                thumbnail
                wide
                _1440x408
                _1103x488
                _1440x960
                _653_432
              }
            }
          }
        }
      }
    }
    events: allNodeEvent(
    sort: {fields: field_start_time, order: ASC}
    limit: 3
    filter: {
      currentMonth: {eq: true}
      field_start_time: {gte: $currentDate}
      langcode: {eq: $langcode}
    }
  ) {
    edges {
      node {
        body {
          summary
        }
        id
        drupal_id
        field_start_time
        created
        field_end_time
        field_ministry {
          drupal_internal__target_id
        }
        field_price_session
        field_serie {
          drupal_internal__target_id
        }
        field_subtitle
        langcode
        title
        path {
          langcode
          alias
        }
        relationships {
          field_serie {
            field_serie_subtitle
            field_total_sessions
            name
          }
          field_image {
            drupal_id
            image_style_uri {
              large
              medium
              thumbnail
              wide
            }
          }
          field_ministry {
            title
            path {
              alias
            }
            langcode
            drupal_id
          }
        }
        currentMonth
        field_series_session_number
        field_location
      }
    }
  }
  podcasts: allNodePodcast(
    sort: {fields: field_date_podcast, order: DESC}
    limit: 1
    filter: {
      langcode: {eq: $langcode}
    }
  ) {
    edges {
      node {
        title
        langcode
        created
        body {
          summary
        }
        path {
          alias
        }
        url: field_audio_url {
          title
          uri
        }
        field_date_podcast
      }
    }
  }
  }
`
