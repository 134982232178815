import React, { useContext } from "react"
import { Link } from "gatsby"
import {homePagePaths, translate } from '../translate'
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import { LanguageContext } from "../context"
import Layout from "../components/layout"

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

const NotFoundPage = ({data, location, pageContext}) => {

  const { t } = useContext(LanguageContext)


  return (
    <Layout
      location={location}
      langcode={pageContext?.langcode}
      translationPaths={homePagePaths}
    >
    <Seo
        title={"No s'ha trobat la pàgina"}
        lang={pageContext?.langcode}
        description={"Ho sentim 😔, no hem pogut trobar el que buscaves"}
        canonicalPath={`/404`}
      />
      <Header
        lang={pageContext?.langcode}
        location={location}
       />
    <main style={pageStyles}>
      <h1 style={headingStyles}>{"No s'ha trobat la pàgina"}</h1>
      <p style={paragraphStyles}>
        {"Ho sentim 😔, no hem pogut trobar el que buscaves."}
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">{"Anar a la pàgina d'inici"}</Link>.
      </p>
    </main>
    <Footer
      lang={pageContext?.langcode}
    />
  </Layout>
  )
}

export default NotFoundPage

export const Head = () => <title>No s'ha trobat la pàgina</title>
