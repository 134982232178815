// gatsby-browser.js
import React from 'react';
import { showLoader, hideLoader } from './src/utils/loader';
import { AuthProvider } from './src/context/AuthContext';
import LanguageContextProvider from './src/context';
import { SSRProvider } from '@react-aria/ssr';
import "@popperjs/core/dist/umd/popper.min";
import "bootstrap/dist/css/bootstrap.min.css";
import './src/assets/css/index.scss';
import { Helmet } from 'react-helmet';

// Define your private paths
const privatePaths = ["/login", "/private-school-page", "/private-lesson-page", "/escola-biblica-online"];

/* export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    // Set default consent mode
    gtag('consent', 'default', {
      'ad_storage': 'denied',
      'analytics_storage': 'denied',
    });

    // Listen for changes in GDPR cookies and update consent mode
    const updateConsentMode = () => {
      const analyticsConsent = document.cookie.includes('gatsby-gdpr-google-analytics=true');
      const tagManagerConsent = document.cookie.includes('gatsby-gdpr-google-tagmanager=true');

      gtag('consent', 'update', {
        'ad_storage': tagManagerConsent ? 'granted' : 'denied',
        'analytics_storage': analyticsConsent ? 'granted' : 'denied',
      });
    };

    document.addEventListener('cookieConsentChanged', updateConsentMode);

    // Initial consent mode update
    updateConsentMode();
  }
};*/

// Handle route changes to show and hide the loader
export const onPreRouteUpdate = () => {
  showLoader();
};

export const onRouteUpdate = () => {
  hideLoader();
};

// Conditionally import Bootstrap JS only in the browser
if (typeof window !== "undefined") {
  import("bootstrap/dist/js/bootstrap.min");
}

// Unified wrapRootElement function for both gatsby-browser.js and gatsby-ssr.js
export const wrapRootElement = ({ element }) => (
  <SSRProvider>
    <LanguageContextProvider langcode="ca">
      <AuthProvider>
        {element}
      </AuthProvider>
    </LanguageContextProvider>
  </SSRProvider>
);
// Add the wrapPageElement function for meta tags
export const wrapPageElement = ({ element, props }) => {
  const isPrivatePath = privatePaths.includes(props.location.pathname);

  return (
    <>
      {isPrivatePath && (
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      )}
      {element}
    </>
  );
};
