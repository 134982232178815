import React, {useContext} from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import Banner from "../components/banner"
import { LanguageContext } from "../context"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Button from "../components/button-visit-us"
import Button2 from "../components/button-generic"
import ActivityItem from "../components/services/activity-item"
import { GatsbyImage } from "gatsby-image"
import InteriorContactItem from "../components/interior-contact-item"

const getIcon = (inputValue) => {
  // Input value
  //const inputValue = '630121307'; // This could be a phone number, email address, or any other value
  // Regular expressions to match phone numbers and email addresses
  const strippedStr = inputValue.replace(/[()\s]/g, '');
  const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Match email addresses
  // Determine the input type based on the input value
  let inputType = '';
  if (phoneRegex.test(strippedStr)) {
    inputType = 'phone';
  } else if (emailRegex.test(strippedStr)) {
    inputType = 'email';
  }
  // Find the icon for the input type
  let icon = '';
  if (inputType) {
    icon = icons.find(i => i.type === inputType).icon;
  }
  return icon;
}

const PageGroups = ({data, location, pageContext}) => {

    const post = data.nodePage
    const groupPage = data?.nodeGroups
    const services = data?.services
    const prayerRoomsHeading = data?.nodeHeading
    const rooms = data?.rooms

      // Dynamically construct translation paths
      const basePath = post?.path?.alias || "";
      const translationPaths = {
        en: `/en${basePath}`, // English path
        es: `/es${basePath}`, // Spanish path
        ca: `${basePath}`, // Catalan path (default)
        default: `${basePath}`, // x-default
      };
     const { t } = useContext(LanguageContext)
     const breakpoints = useBreakpoint()


    return (
        <Layout
          location={location}
          langcode={post.langcode}
          translationPaths={translationPaths}
        >
        <Seo
            title={post.title}
            lang={post.langcode}
            description={post.body.summary}
            image={post?.relationships?.field_image?.image_style_uri?._653_432}
            canonicalPath={basePath}
          />
          <Header
            lang={pageContext?.langcode}
            location={location}
           />

           <div className="page-groups">
           <Banner
             post={post}
             height={"408px"}
           />

           <section className="section-to-know-us p-t-80 p-b-80 medium-p-b-54 small-p-t-24 small-p-l-r-16 small-p-b-64">
               <div className="container p-0 toknowus">
               <h2 className="H2-Medium-C---bordeaux p-b-88 medium-m-b-74 small-m-b-16 medium-p-b-0 small-p-b-0">
                 {"Sobre els nostres grups"}
               </h2>
               <SectionAboutGroups
                 post={groupPage}
                 title={"Coneix-nos"}
                 imagePos="left"
                 color="bordeaux"
               />
               <SectionAboutGroups
                 post={groupPage}
                 title={"Coneix-nos"}
                 imagePos="right"
                 color="bordeaux"
               />
               <SectionServices
                post={post}
                services={services}
                title="Llistat de les activitats en grup"
               />
               <SectionPrayerRooms
                post={prayerRoomsHeading}
                rooms={rooms}
               />
               <SectionConnectUs
                post={groupPage}
               />
             </div>
           </section>

           </div>
      <Footer
        lang={pageContext?.langcode}
      />
    </Layout>
  )
}

// Array of icons
const icons = [
  { type: 'phone', icon: 'icons-32-x-32-export-phone-call' },
  { type: 'email', icon: 'icons-32-x-32-export-mail' },
  { type: 'social', platform: 'facebook', icon: 'icons-32-x-32-export-facebook' },
  { type: 'social', platform: 'twitter', icon: 'twitter-x-seeklogocom-4' },
  { type: 'social', platform: 'linkedin', icon: 'icons-32-x-32-export-linkedin' }
];


const SectionConnectUs = (props) => {
  const { post } = props

  return(
    <section className="groups-connect-us">
      <div className="container">
        <h2 className="H2-Medium-C---bordeaux m-b-32 d-flex justify-content-center small-m-b-16">
          {post?.field_connect_title}
        </h2>
        <div className="gp-contanct-content center-section ">
        <div dangerouslySetInnerHTML={{__html: post?.field_connect_body?.value}} className="groups-connect-desc m-b-36" />
        <div className="groups-contacts row m-b-68">
          <div className="row">
          {post?.field_contacts && post?.field_contacts.map((contact,index) => {
            return (
              <InteriorContactItem
                key={index}
                icon={getIcon(contact)}
                text={contact}
                className={index === 0 ? `offset-lg-3 col-lg-3` : `col-lg-3`}
              />
            )
          })}
          </div>
        </div>
        <div className="groups-contact-image">
          <img src={post?.relationships?.field_connect_image?.image_style_uri?._653_432} alt={post?.field_connect_title} />
        </div>
        </div>
      </div>
    </section>
  )
}

const SectionPrayerRooms = (props) => {
  const { post, rooms } = props

  return(
      <React.Fragment>
        <section className="section-prayer-rooms m-b-80 small-m-b-32">
          <div className="container background-beig">
            <div className="section-head row m-b-40 small-m-b-32">
              <div className="col-lg-6 col-md-6 section-image">
              {post.relationships.field_image && (
                <ResponsiveImage
                  image={post?.relationships?.field_image?.image_style_uri?._653_432}
                  alt={post?.title}
                />
                )}
              </div>
              <div className="col-lg-6 col-md-6 section-text">
              <h3>{post.title}</h3>
              <div dangerouslySetInnerHTML={{__html: post.body.value}} />
              </div>
            </div>
            <div className="section-body row">
              {rooms && rooms?.edges?.map(({node}, index) => {
                return(
                  <ActivityItem
                    title={node.title}
                    //image={node?.relationships?.field_image?.image_style_uri?._653_432}
                    key={index}
                    classes={`item item-${index+1} col-6 col-md-4 col-lg-4 p-0 m-b-58 small-m-b-32 ${(index+1)%2 === 0 ? '' : 'small-p-r-16'} ${index === 0 ? 'm-l-5' : ''}`}
                    url={node?.path?.alias}
                    //descriptionOnModal={true}
                    description={node?.body?.value}
                    datehour={node?.field_when}
                    //personInCharge={node?.field_person_in_charge[0]}
                    place={node?.field_where}
                    periodicity={node?.field_periodicity}
                  />
                )
              })}
            </div>
          </div>
        </section>
      </React.Fragment>
  )
}

const SectionServices = (props) => {
  const {services, title} = props
  return (
    <React.Fragment>
    {title &&
      <section className="section-title container">
        <div className="d-flex justify-content-center">
          <h2 className="H2-Medium-C---green-dark p-b-56 small-p-b-16">{title}</h2>
        </div>
      </section>
    }
    <section className="section-content p-b-80 small-p-b-0 medium-p-b-95">
      <div className="container p-0">
        <div className="row small-0 offset-lg-2 offset-md-0 list-activities">
        {services && services?.edges?.map(({node}, index) => {
          return(
            <ActivityItem
              title={node.title}
              image={node?.relationships?.field_image?.image_style_uri?._653_432}
              key={index}
              classes={`item item-${index+1} col-6 col-md-4 col-lg-3 p-0 m-b-58 small-m-b-32 ${(index+1)%2 === 0 ? '' : 'small-p-r-16'} ${index === 0 ? 'm-l-5' : ''}`}
              url={node?.path?.alias}
              descriptionOnModal={true}
              description={node?.body?.value}
              datehour={node?.field_when}
              personInCharge={node?.field_person_in_charge[0]}
              buttonType="modal"
              place={node?.field_where}
              periodicity={node?.field_repeat}
            />
          )
        })}
        </div>
      </div>
    </section>
    </React.Fragment>
  )
}

const SectionAboutGroups = (props) => {
  const {title, post, imagePos} = props
  const breakpoints = useBreakpoint()
  const { t } = useContext(LanguageContext)

if(imagePos === "left"){
  return(
    <div className="row img-left toknowus-item m-b-120 medium-m-b-80">
       <div className="col-12 col-sm-6 background-bordeux first-item max-width-683 image-wrapper">
          <img
            src={post?.relationships?.field_image_1?.image_style_uri?._653_432}
            alt={post?.field_connect_title?.title}
            className="m-r--24"
          />
       </div>
       <div className="col-12 col-sm-6 p-l-80 p-r-169 medium-p-48-24 small-p-0">
          <div className="m-b-32" dangerouslySetInnerHTML={{__html: post?.field_body_1?.value}} />
          <div className="row">
             <div className="container">
                <div className="col-12 button-cta align-left hide">
                   <Button
                      className="btn btn-default btn-outline-visit-us bordeaux"
                      title={post?.field_meet_cta_1?.title}
                      />
                </div>
             </div>
          </div>
       </div>
    </div>
  )
  }

  if(imagePos === "right"){
    if(breakpoints.sm){
      return(
        <div className="row img-left toknowus-item m-b-120 medium-m-b-114">
           <div className="col-12 col-sm-6 background-bordeux first-item max-width-683 image-wrapper">
              <img
                src={post?.relationships?.field_image_2?.image_style_uri?._653_432}
                alt={post?.field_connect_title?.title}
                className="m-r--24"
              />
           </div>
           <div className="col-12 col-sm-6 p-l-80 p-r-169 medium-p-48-24 small-p-0">
              <div className="m-b-32" dangerouslySetInnerHTML={{__html: post?.field_body_2?.value}} />
              <div className="row hide">
                 <div className="container">
                    <div className="col-12 button-cta align-left hide">
                       <Button
                          className="btn btn-default btn-outline-visit-us bordeaux"
                          title={post?.field_connect_title?.title}
                          />
                    </div>
                 </div>
              </div>
              <div className="row groups-contacts">
              {post?.field_contacts && post?.field_contacts.map((contact,index) => {
                return (
                  <InteriorContactItem
                    key={index}
                    icon={getIcon(contact)}
                    text={contact}
                    className={index === 0 ? `col-lg-6` : `col-lg-6`}
                  />
                )
              })}
              </div>
           </div>
        </div>
      )
    }else{
      return(
        <div className="row img-right toknowus-item pastoral-team m-b-80">
           <div className="col-12 col-sm-6 p-l-169 p-r-82 m-r-3p medium-p-24-48 small-p-0">
              <div className="m-b-32" dangerouslySetInnerHTML={{__html: post?.field_body_2?.value}} />
              <div className="row">
                 <div className="container">
                    <div className="col-12 button-cta align-left hide">
                       <Button2
                          className="btn btn-default btn-outline-visit-us bordeaux"
                          title={post?.field_connect_title?.title}
                          onClick={() => navigate("/equip-pastoral")}
                          />
                    </div>
                 </div>
              </div>
              <div className="row groups-contacts">
              {post?.field_contacts && post?.field_contacts.map((contact,index) => {
                return (
                  <InteriorContactItem
                    key={index}
                    icon={getIcon(contact)}
                    text={contact}
                    className={index === 0 ? `col-lg-6` : `col-lg-6`}
                  />
                )
              })}
              </div>
           </div>
           <div className="col-12 col-sm-6 background-bordeux second-item max-width-683 small-p-0">
              <img
                src={post?.relationships?.field_image_2?.image_style_uri?._653_432}
                alt={post?.field_connect_title?.title}
                className="m-r--24"
              />
           </div>
        </div>
      )
    }
  }
}

const ResponsiveImage = (props) => {
  const { image, alt } = props
 return (
   <img
     src={image}
     alt={alt}
   />
 );
}

export const pageQuery = graphql`
query PageGroups ($drupal_internal__nid: Int, $langcode: String!) {
    nodePage (
      drupal_internal__nid: {eq: $drupal_internal__nid},
      langcode:{eq: $langcode}
      ){
        title
        path{
          alias
        }
        body {
          processed
          summary
          value
        }
        relationships{
          field_image{
            image_style_uri {
              _120x120
              _1440x408
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
        }
      }
      services: allNodeServices(
        filter: {
          field_need_volunteers: {eq: false}
          langcode:{eq: $langcode}
        }
        ) {
      edges {
        node {
          field_need_volunteers
          field_person_in_charge
          field_minimum
          field_duration_hours
          field_when
          field_where
          field_repeat
          path{
            alias
          }
          relationships {
            field_ministry {
              title
            }
            field_image {
              image_style_uri {
                _653_432
              }
            }
          }
          title
          body {
            processed
          }
        }
      }
    }
    nodeHeading(field_main_content: {drupal_internal__target_id: {eq: $drupal_internal__nid}}) {
      title
      body{
        value
      }
      field_image{
        alt
      }
      relationships {
          field_image {
            gatsbyImage(width: 600, layout: FIXED)
            image_style_uri {
              _653_432
            }
          }
          field_main_content {
            title
            drupal_id
            drupal_internal__nid
          }
        }
      }
      rooms: allNodePrayerRoom {
        edges {
          node {
            field_contact
            field_periodicity
            field_when
            field_where
            title
          }
        }
      }
      nodeGroups(
          langcode:{eq: $langcode}
        ) {
        title
        field_contacts
        field_body_1 {
          processed
          value
        }
        field_body_2 {
          processed
          value
        }
        field_connect_body {
          processed
          value
        }
        field_connect_title
        relationships {
          field_image_1 {
            image_style_uri {
              _653_432
            }
          }
          field_image_2 {
            image_style_uri {
              _653_432
            }
          }
          field_connect_image {
            image_style_uri {
              _653_432
            }
          }
        }
    }
}
`

export default PageGroups
