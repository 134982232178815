import React, { useContext } from "react"
import { LanguageContext } from "../context"
import Icon from "./icon"
import Utils from "../utils"
import moment from "moment"
import 'moment/min/locales'
import { Link } from "gatsby"


//const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

 const Ribbon = (props) => {

   const { t } = useContext(LanguageContext)
   const { lang, preachSeries, className } = props

   //console.log("PREACHSERIES,", preachSeries)

   moment.locale(lang);
   const nextSunday = Utils.getSundayOfCurrentWeek()
   //console.log("next sunday, 21", nextSunday,preachSeries[0]?.node?.field_series_session_number )
   const day = moment(nextSunday).format('D')
   const month = moment(nextSunday).format('MMMM')
   const de = Utils.startWithVowel(month,lang) ? "d'" : lang === "en" ? "" : "de " //check if its english
   const dayMonth = `${day} ${de}${month}`;

   return (
     <section className={`row ribbon-home m-b-110 medium-p-l-r-24 ${className}`}>
       <div className="container ribbon-content">
       <div className="row">
        <div className="col-md-4 ribbon-line ribbon-element first">
          <div className="ribbon-title">{t("Sèrie de predicacions")}</div>
          {preachSeries && preachSeries[0]?.node?.relationships?.field_serie?.name ?
            <>
            <div className={` ${preachSeries[0]?.node?.relationships?.field_serie?.name.length > 24 ? 'more-24' : ''} ribbon-info series`}>
              {preachSeries[0]?.node?.relationships?.field_serie?.name}
            </div>
            <div className="ribbon-footer">{t("Sessió")} {preachSeries[0]?.node?.field_series_session_number} {t("de")} {preachSeries[0]?.node?.relationships?.field_serie?.field_total_sessions}
            </div>
            </>
            :
            <div className="ribbon-info series">
             --
            </div>
           }
           {!preachSeries &&
             <div className="ribbon-info series">
              --
             </div>
           }


        </div>
        <div className="col-md-4 ribbon-line p-l-30 ribbon-element">
          <div className="row">
            <div className="col-md-2 p-0 ribbon-icon">
              <span>
              <Icon
                icon="icons-32-x-32-export-clock"
                size={20}
                color="#9a2323"
              />
              </span>
            </div>
            <div className="col-md-10 p-0 ribbon-element">
              <div className="ribbon-title">{t("Proper Culte")}</div>
              <div className="ribbon-info">{t("Diumenge")}, {dayMonth} <br /> {t("a les 11:00h")}</div>
            </div>
          </div>
        </div>
        <div className="col-md-4 p-l-30 ribbon-element last">

          <div className="row">
            <div className="col-md-2 p-0 ribbon-icon">
              <span>
              <Icon
                  icon="icons-32-x-32-export-maps"
                  size={20}
                  color="#9a2323"
              />
              </span>
            </div>
            <div className="col-md-10 p-0 ribbon-element">
              <div className="ribbon-title ">{t("Com asssitir")}</div>
              <div className="ribbon-info">
                {t("A les")} <Link to="/contacte"> {t("nostres instal·lacions")} </Link> <br /> {t("o al nostre")} <Link to="/mira"> {t("servei online")} </Link></div>
            </div>
          </div>

        </div>
       </div>
      </div>
     </section>
   )
 }

 export default Ribbon
