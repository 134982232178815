import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import DonationsPage from "../components/pages/donations-page"

const PageDonations = ({ data, location, pageContext }) => {

  const post = data?.nodePage
  const english = data?.english
  const spanish = data?.spanish
  //const catalan = data?.catalan
  const events = data?.event
  const ministries = data?.ministries
  const mainMinistries  = data?.mainMinistries
  const promoted = data?.promoted
  const donations = data?.donations
  const localSearchPages = data?.localSearchPages
  const services = data?.services

  // Dynamically construct translation paths
  const basePath = post?.path?.alias || "";
  const translationPaths = {
    en: `/en${basePath}`, // English path
    es: `/es${basePath}`, // Spanish path
    ca: `${basePath}`, // Catalan path (default)
    //default: `${basePath}`, // x-default
  };

  return (
    <Layout
      location={location}
      langcode={post.langcode}
      translationPaths={translationPaths}
    >
    <Seo
        title={post.title}
        lang={post.langcode}
        description={post.body.summary}
        canonicalPath={basePath}
      />
      <Header
        lang={pageContext?.langcode}
        location={location}
       />

        <DonationsPage
          post={post}
          lang={post.langcode}
          events={events}
          bannerHeight="512px"
          ministries={mainMinistries}
          promoted={promoted}
          buttonBanner="Comença a servir"
          donations={donations}
          localSearchPages={localSearchPages}
          pageContext={pageContext}
          services={services}
         />

      <Footer
        lang={pageContext?.langcode}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
      query PageDonations($drupal_internal__nid: Int, $langcode: String!) {
        nodePage(
          drupal_internal__nid: {eq: $drupal_internal__nid},
          langcode:{eq: $langcode}
          ) {
          langcode
          drupal_internal__nid
          id
          title
          path{
            alias
          }
          drupal_id
          body {
            value
            summary
          }
          created
          relationships {
            field_image {
              image_style_uri {
                _294x192
                _541x359
                _653_432
                large
                medium
                thumbnail
                wide
              }
            }
          }
        }
        donations: nodeDonations {
          title
          body {
            processed
            value
          }
          relationships {
            field_image {
              image_style_uri {
                _294x192
                _541x359
                _653_432
                large
                medium
                thumbnail
                wide
              }
            }
            field_volunteering_image {
              image_style_uri {
                _294x192
                _541x359
                _653_432
                large
                medium
                thumbnail
                wide
              }
            }
            field_form {
              elements {
                attributes {
                  name
                  value
                }
                type
                name
              }
            }
          }
          field_cta {
            title
            uri
          }
          field_volunteering_title
          field_volunteering_body {
            value
          }
          field_volunteering_cta {
            title
            uri
          }
        }
}
`

export default PageDonations
