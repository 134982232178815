import React, { useContext, useState, useEffect }  from "react"
import { LanguageContext } from "../context"
import { graphql, navigate } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import ColumnPressKit from "../components/page-press-kit/articles-press-kit"
import Icon from "../components/icon"
import ArticlesPressKit from "../components/page-press-kit/articles-press-kit"
import { homePagePaths, translate } from '../translate'
import Video from "../components/video"
import Banner from "../components/banner"
import BlogList from "../components/blog/blog-list"


const PagePressKit = ({
    data,
    location,
    pageContext
  }) => {

  const allNodePressKit = data.allNodePressKit

  const post = data.nodePage
  const featuredItem = data.nodePressKit
  const { t } = useContext(LanguageContext)
  const fullUrl = typeof window !== 'undefined' ? window.location.href : '';
  const breakpoints = useBreakpoint()

    // Dynamically construct translation paths
    const basePath = post?.path?.alias || "";
    const translationPaths = {
       en: `/en${basePath}`, // English path
       es: `/es${basePath}`, // Spanish path
       ca: `${basePath}`, // Catalan path (default)
    };

 // State for the list
 const [list, setList] = useState([...allNodePressKit?.nodes?.slice(0, 12)]);

 // State to trigger oad more
 const [loadMore, setLoadMore] = useState(false);
 const [showMessage, setShowMessage] = useState(false);

 // State of whether there is more to load
 const [hasMore, setHasMore] = useState(allNodePressKit?.nodes?.length > 12);

 // Load more button click
 const handleLoadMore = () => {
   setLoadMore(true);
 };

 // Handle loading more articles
 useEffect(() => {
   if (loadMore && hasMore) {
     const currentLength = list.length;
     const isMore = currentLength < allNodePressKit?.nodes?.length;
     const nextResults = isMore
       ? allNodePressKit?.nodes?.slice(currentLength, currentLength + 12)
       : [];
     setList([...list, ...nextResults]);
     setLoadMore(false);
     setShowMessage(true)
   }
 }, [loadMore, hasMore]); //eslint-disable-line

 //Check if there is more
 useEffect(() => {
   const isMore = list.length < allNodePressKit?.nodes?.length;
   setHasMore(isMore);
 }, [list]); //eslint-disable-line

  return (
    <div>
      <Layout
        translationPaths={translationPaths}
        location={location}
        langcode={post?.langcode}
        className={`page-press-kit`}
        >
        <Seo
            title={post?.title}
            lang={post?.langcode}
            description={post?.body?.summary}
            image={post?.relationships?.field_image?.image_style_uri?._653_432}
            canonicalPath={basePath}
          />
          <Header
            lang={pageContext?.langcode}
            location={location}
          />
          <Banner
            post={post}
            height={"480px"}
            backgroundColor="green"
          />
          <BlogList
           blogPosts={list}
           lang={pageContext?.langcode}
           className="blog-list m-b-108 p-t-80"
           classContainer="container p-0"
           classRow="col-12 col-md-3"
           includeTags={true}
           featuredItem={featuredItem}
           includeSource={true}
          />
          <Footer
            lang={pageContext?.langcode}
            location={location}
          />
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
query PagePressKit ($drupal_internal__nid: Int, $langcode: String!, $limit: Int){
  nodePage (drupal_internal__nid: {eq: $drupal_internal__nid},langcode:{eq: $langcode}){
    title
    path{
      alias
    }
    body {
      value
    }
    relationships{
      field_image{
        image_style_uri{
          wide
          _294x192
        }
      }
    }
  }
  allNodePressKit
    (
      filter:
      {
        langcode: {eq: $langcode},
        sticky: {eq: false}
      }
      sort: {order: DESC, fields: field_publication_date}
      limit: $limit
      ) {
    nodes{
        title
        internal {
          type
         }
        created(formatString: "DD MMMM YYYY", locale: $langcode)
        path{
          alias
        }
        field_publication_date(formatString: "DD MMMM YYYY", locale: $langcode)
        field_publisher
        field_url_youtube
        field_cta{
          title
          uri
        }
        body {
          processed
          summary
          value
        }
          relationships{
          field_tag{
            name
          }
          field_video{
            uri{
              url
            }
          }
          field_category{
            name
            path{
              alias
            }
          }
          field_video{
            uri{
              url
            }
          }
          field_image{
            image_style_uri{
              wide
              _294x192
            }
            uri{
              url
            }
          }
        }
      }
      }
      nodePressKit(sticky: {eq: true}, langcode: {eq: $langcode}){
        title
        internal {
          type
         }
         created(formatString: "DD MMMM YYYY", locale: $langcode)
        path{
            alias
         }
         body {
              value
              summary
            }
            field_publication_date(formatString: "DD MMMM YYYY", locale: $langcode)
            field_publisher
            field_url_youtube
            field_cta{
              title
              uri
            }
              relationships{
              field_tag{
                name
              }
              field_video{
                uri{
                  url
                }
              }
              field_category{
                name
                path{
                  alias
                }
              }
              field_video{
                uri{
                  url
                }
              }
              field_image{
                image_style_uri {
                  large
                  medium
                  thumbnail
                  wide
                  _294x192
                  _541x359
                  _653_432
                }
              }
            }
      }

}
`

export default PagePressKit
