import React, { useContext } from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout";
import { homePagePaths } from "../translate";
import Header from "../components/header";
import Footer from "../components/footer";
import { LanguageContext } from "../context";
import { AuthContext } from "../context/AuthContext";
import Button from "../components/button-generic";
import Filters from "../components/search/filters";
import SharePostSocialMedia from "../utils/social-media/share";
import SimilarItems from "../components/blog/similar-school-items";
import Video from "../components/video";
import Icon from "../components/icon";
import Seo from "../components/seo";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const PagePrivateLesson = ({
  data,
  location,
  pageContext,
  categories,
  refreshList,
  initialQuery,
}) => {
  const post = data?.nodeLesson;
  const isBrowser = typeof window !== "undefined"; // Check if running in the browser

  // Access context values unconditionally
  const authContext = useContext(AuthContext);
  const languageContext = useContext(LanguageContext);

  // Safely access context values
  const { t } = isBrowser ? languageContext : { t: (text) => text };
  const { user, loading } = isBrowser && authContext ? authContext : { user: null, loading: false };
  const fullUrl = isBrowser ? window.location.href : '';
  const breakpoints = useBreakpoint();

  // Determine the access level of the current page
  const currentAccessLevel = post?.field_access_control || null;

      // Dynamically construct translation paths
   const basePath = post?.path?.alias || "";
   const translationPaths = {
      en: `/en${basePath}`, // English path
      es: `/es${basePath}`, // Spanish path
      ca: `${basePath}`, // Catalan path (default)
   };

  // If the user is not authenticated, show the login prompt
  if (loading) {
    return <div>{t("Loading...")}</div>;
  }

  if (!user) {
    return (
      <Layout
        translationPaths={translationPaths}
        location={location}
        langcode={post?.langcode}
      >
        <Seo
          title={post?.title}
          lang={post?.langcode}
          description={post?.body?.summary}
          canonicalPath={basePath}
        />
        <Header lang={post?.langcode} location={location} />
        <div className="container private-page-container">
          <div className="private-page-content">
            <h2>{t("This is a private page. Please log in to access it.")}</h2>
            <Button
              className="btn-outline"
              title={t("Log In")}
              onClick={() => navigate("/login")}
            />
          </div>
        </div>
        <Footer lang={post?.langcode} />
      </Layout>
    );
  }

  // Render the full content for authenticated users
  return (
    <Layout
      translationPaths={homePagePaths}
      location={location}
      langcode={post?.langcode}
    >
      <Seo
        title={post?.title}
        lang={post?.langcode}
        description={post?.body?.summary}
        image={post?.relationships?.field_image?.image_style_uri?._653_432}
      />
      <Header lang={post?.langcode} location={location} />
      <section className="section-blog-list-filters m-t-64 p-t-52 hide">
        <div className="container p-0">
          <Filters
            buttons={[
              <Button
                className="btn-all-blog-posts p-0"
                title="Tots els Posts"
                wrapperclassName="col-2- p-l-0 p-r-0 all-ministries-wrapper m-r-32"
                action="/blog"
              />,
              <Button
                className="btn-dropdown filter-blog-post"
                type="dropdown"
                title="Totes les Categories"
                options={categories}
                wrapperclassName="col-2- p-l-0 p-r-0 all-dropdown-wrapper"
                action="/blog"
              />,
            ]}
            action="/blog"
            marginbottom="m-b-43"
            marginTop="m-t-28"
            hasSearch={true}
            searchPlaceholder="Cerca per post"
            initialQuery={initialQuery}
            refreshList={refreshList}
          />
        </div>
      </section>
      <section className="m-t-64 m-b-32 title-section small-m-t-40 small-m-b-16">
        <div className="container">
          <div className="row small-0">
            <div className="col-12 col-md-10 offset-md-1 p-0">
              <h1
                className={`bordeaux ${
                  breakpoints.sm
                    ? "justify-content-center"
                    : "justify-content-left"
                } d-flex p-t-36 m-b-0`}
              >
                {post?.title}
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="interior-content content-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-6 offset-md-1 p-0- m-r-20 p-l-0 p-r-10 medium-0 small-0">
              <div
                className={`col-10- img-interior-ministry p-0 m-b-24 ${
                  breakpoints.sm_md ? "d-flex justify-content-center" : ""
                }`}
              >
                <Video
                  videoSrcURL={post?.field_url_youtube}
                  videoTitle={post?.title}
                />
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: post?.body?.processed }}
                className="simple-page p-b-55 small-p-0"
              />
              <div className="lesson-questions p-b-55">
                <h3 className="H4-Medium-C---green-dark text-start">
                  {" "}
                  Arxiu PDF amb els apunts per aquesta sessió:{" "}
                </h3>
                <a
                  href={post?.relationships?.field_notes_and_questions?.uri?.url}
                  alt="lliçó"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="download-lesson"
                >
                  <span>
                    <Icon
                      icon="icons-32-x-32-export-download"
                      className="icon download-icon m-r-12"
                      size={20}
                    />{" "}
                    Descarregar l'arxiu
                  </span>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-3 offset-md-1 sidebar p-0 medium-100">
              <div className="box-2 share-social-media m-b-66">
                <SharePostSocialMedia
                  imageUrl={post?.relationships?.field_image?.image_style_uri?._653_432}
                  className="share-this"
                  url={fullUrl}
                  title={post?.title}
                  socialmedia={["twitter", "facebook", "linkedin", "pinterest"]}
                  description={post?.body?.summary}
                  boxTitle="Compartir estudi"
                />
              </div>
              <div className="box-3 related-posts small-m-b-64 medium-m-b-40">
                <div className="box-title Small-Bold-L---Bordeaux m-b-18">
                  {"cursos recomanats"}
                </div>
                <SimilarItems
                  category={post?.relationships?.field_category}
                  tags={post?.relationships?.field_tag}
                  currentItemSlug={post?.path?.alias}
                  lang={post?.langcode}
                  currentAccessLevel={currentAccessLevel}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer lang={post?.langcode} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query PrivateLessonQuery($drupal_internal__nid: Int, $langcode: String!) {
    nodeLesson(
      drupal_internal__nid: { eq: $drupal_internal__nid }
      langcode: { eq: $langcode }
    ) {
      drupal_id
      title
      body {
        summary
        processed
      }
      created(locale: $langcode, formatString: "D MMMM Y")
      langcode
      path {
        alias
      }
      field_access_control
      field_url_youtube
      relationships {
        field_category: field_serie {
          name
          path {
            alias
          }
        }
        field_tag {
          name
          path {
            alias
          }
        }
        field_notes_and_questions {
          uri {
            url
          }
        }
        field_image {
          image_style_uri {
            _120x120
            _1440x408
            _294x192
            _541x359
            _653_432
            _1103x488
            large
            medium
            thumbnail
            wide
          }
        }
      }
    }
  }
`;

export default PagePrivateLesson;
