import React, { useContext, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { homePagePaths } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import { LanguageContext } from "../context"
import Button from "../components/button-generic"
import Filters from "../components/search/filters"
import AuthorCard from "../utils/blog/author-card"
import SharePostSocialMedia from "../utils/social-media/share"
import SimilarItems from "../components/blog/similar-items"
import DrupalComments from '../../plugins/gatsby-plugin-drupal-comments-unida/src'
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Seo from "../components/seo"
import { debounce } from 'lodash'

const prefixAliasWithLanguage = (alias, langcode = 'ca') => (
    langcode === 'ca'
    ? `/author/${alias}`
    : `/${langcode}/author/${alias}`
  )


const PageBlogPost = ({
    data,
    location,
    pageContext,
    categories,
    refreshList,
    initialQuery
  }) => {
    const post = data?.nodeBlogPost
    const { t } = useContext(LanguageContext)
    const fullUrl = typeof window !== 'undefined' ? window.location.href : ''
    const breakpoints = useBreakpoint()
    const contentId = post?.drupal_internal__nid
    // Construct translationPaths dynamically based on the current post
    const basePath = post?.path?.alias || "";
    const translationPaths = {
      en: `/en${basePath}`, // English path
      es: `/es${basePath}`, // Spanish path
      ca: `${basePath}`, // Catalan path (default)
     // default: `${basePath}`, // x-default uses the same as Catalan
    };

    const sendViewCount = debounce(() => {
      const username = process.env.GATSBY_BASIC_AUTH_USERNAME
      const password = process.env.GATSBY_BASIC_AUTH_PASSWORD
      const encodedCredentials = btoa(`${username}:${password}`);
      if (typeof window !== "undefined") {
        fetch(`${process.env.GATSBY_DRUPAL_API_URL}/d-stats/record-view`, {
          method: 'POST',
          headers: {
              'Authorization': `Basic ${encodedCredentials}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ content_id: contentId }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
        })
        .catch((error) => {
            //console.error('Error:', error);
        });
      }

    }, 5000); // Debounce for 5 seconds


  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
        sendViewCount();
    }else{
      console.log("We are in dev....", contentId)
    }
    // Cleanup function
    return () => sendViewCount.cancel();
  }, [contentId, sendViewCount]);

 


    const getCategory = () => {
      let cats = []
      cats = post?.relationships?.field_category.map((cat) => {
        return {'name': cat.name, 'path': cat.path.alias, 'type': 'cat'}
      })
      let tags = []
      tags = post?.relationships?.field_tag.map((tag) => {
        return {'name': tag.name, 'path': tag.path.alias, 'type': 'tag'}
      })
      const catsTags = [...new Set([...cats, ...tags])];
      return catsTags?.map(item => (
        <React.Fragment key={item.name}>
          <Link to={item.path} className={item.type}>{item.name}</Link>
          {/*<span className={item.type}>{item.name}</span>*/}
          {catsTags.indexOf(item) !== catsTags.length - 1 ? ', ' : ''}
        </React.Fragment>
      ));
    }

    const getFullName = (post) => {
      if(post?.author?.field_name && post?.author?.field_surname){
        return `${post.author.field_name} ${post.author.field_surname}`
      }
      if(post?.author?.field_name){
        return post?.author.field_name
      }
      return 'Comunicació UNIDA'
    }

    const getAuthorUrl = (post) => {
      if(post?.author?.field_name && post?.author?.field_surname){
        return `${post.author.field_name} ${post.author.field_surname}`
      }
      if(post?.author?.field_name){
        return post?.author.field_name
      }
      return 'Comunicació UNIDA'
    }

    return (
          <Layout
            translationPaths={translationPaths}
            location={location}
            langcode={post?.langcode}
          >
          <Seo
              title={post?.title}
              lang={post?.langcode}
              description={post?.body?.summary}
              image={post?.relationships?.field_image?.image_style_uri?._653_432}
              canonicalPath={post?.path?.alias}
            />
            <Header
              lang={pageContext?.langcode}
              location={location}
            />
            <div className="page-blog-post">
            <section className="section-blog-list-filters m-t-64 p-t-52 hide">
            <div className="container p-0">
             <Filters
                  buttons={[
                    <Button
                     className="btn-all-blog-posts p-0"
                     title="Tots els Posts"
                     wrapperclassName="col-2- p-l-0 p-r-0 all-ministries-wrapper m-r-32"
                     action="/blog"
                     />,
                    <Button
                     className="btn-dropdown filter-blog-post"
                     type="dropdown"
                     title="Totes les Categories"
                     options={categories}
                     wrapperclassName="col-2- p-l-0 p-r-0 all-dropdown-wrapper"
                     action="/blog"
                     />,
                   ]}
                  //searchQuery={searchQuery}
                  //setSearchQuery={setSearchQuery}
                  action="/blog"
                  marginbottom="m-b-43"
                  marginTop="m-t-28"
                  hasSearch={true}
                  searchPlaceholder="Cerca per post"
                  initialQuery={initialQuery}
                  refreshList={refreshList}
              />
              </div>
            </section>
            <section className="m-t-64 m-b-32 title-section small-m-t-40 small-m-b-16 ">
              <div className="container">
                <div className="row small-0">
                  <div className="col-12 col-md-10 offset-md-1 p-0 title-wrapper">
                    <h1 className={`bordeaux ${breakpoints.sm ? 'justify-content-center' : 'justify-content-left'} d-flex p-t-36 m-b-0`}>
                      {post?.title}
                    </h1>
                 </div>
                </div>
              </div>
              </section>
              <section className="interior-content content-section">
               <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-6 offset-md-1 p-0- m-r-20 p-l-0 p-r-10 medium-0">
                  <div className={`col-10- img-interior-ministry p-0 m-b-24 ${breakpoints.sm_md ? 'd-flex justify-content-center' : ''}`}>
                    <img
                     src={post?.relationships?.field_image?.image_style_uri?._653_432}
                     alt={post?.title}
                     />
                  </div>
                    <div
                      dangerouslySetInnerHTML={{__html: post?.body?.processed}}
                      className="simple-page p-b-80"
                    />
                    <div className="comments m-b-80">
                    <DrupalComments
                         entity_id = {post?.drupal_id}
                         type_comment = "comment"
                         field_name = "field_comment"
                         type_content = "blog_post"
                         entity_type = "node"
                         url_api = {`${process.env.GATSBY_DRUPAL_API_URL}/jsonapi/comment/comment`}
                         login_api = {`${process.env.GATSBY_BASIC_AUTH_USERNAME}`}
                         password_api = {`${process.env.GATSBY_BASIC_AUTH_PASSWORD}`}
                         fields_names ={[
                           {'name': 'Nom'},
                           {'name': 'E-mail'},
                           {'name': 'Tema'},//["data-params"]["data-fields_names"][0].name
                           {'name': 'Missatge'},
                           {'name': 'Enviar'}
                         ]}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-3 offset-md-1 sidebar p-0 medium-100">
                  <div className="box-1 post-author">
                    <AuthorCard
                      name={getFullName(post)}
                      photo={post?.author?.relationships?.field_image?.image_style_uri?.medium}
                      date={post?.created}
                      tagsCategories={getCategory()}
                      className="author-card background-beig m-b-24"
                      tagline={post?.author?.field_tagline}
                      authorUrl={prefixAliasWithLanguage(post?.author.name,post?.langcode)}
                    />

                  </div>
                  <div className="box-2 share-social-media m-b-66">
                    <SharePostSocialMedia
                      imageUrl={post?.relationships?.field_image?.image_style_uri?._653_432}
                      className="share-this"
                      url={fullUrl}
                      title={post?.title}
                      socialmedia={[
                        'twitter',
                        'facebook',
                        'linkedin',
                        'pinterest'
                      ]}
                      description={post?.body?.summary}
                    />
                  </div>
                  <div className="box-3 related-posts small-m-b-64 medium-m-b-40">
                    <div className="box-title Small-Bold-L---Bordeaux m-b-18">
                      {"articles recomanats"}
                    </div>
                    <SimilarItems
                      category={post?.relationships?.field_category}
                      tags={post?.relationships?.field_tag}
                      currentItemSlug={post?.path?.alias}
                      lang={post?.langcode}
                     />
                  </div>
                  </div>
                </div>
              </div>
              </section>
              </div>
            <Footer
              lang={pageContext?.langcode}
              location={location}
             />
          </Layout>
    )
}



export const pageQuery = graphql`
query PageBlogPost ($drupal_internal__nid: Int, $langcode: String!) {
    nodeBlogPost(
        drupal_internal__nid: {eq: $drupal_internal__nid},
        langcode:{eq: $langcode}
        ){
      drupal_id
      drupal_internal__nid
      title
      body{
            summary
            processed
      }
      created(locale: $langcode, formatString: "D MMMM Y")
      langcode
      path{
        alias
      }
      author {
        relationships {
          field_image {
            image_style_uri {
              _653_432
              _120x120
              medium
            }
          }
        }
        field_tagline
        field_surname
        field_name
        name
      }
      relationships {
        field_category {
          name
          path {
            alias
          }
        }
        field_tag {
          name
          path {
            alias
          }
        }
        field_image {
          image_style_uri {
            _120x120
            _1440x408
            _294x192
            _541x359
            _653_432
            _1103x488
            large
            medium
            thumbnail
            wide
          }
        }
      }
    sticky
    }
 }
`

export default PageBlogPost
