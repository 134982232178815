import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"

const PagePhotoGallery = ({data}) => {
    const nodePhotoBlogPost = data.nodePhotoBlogPost
    // Dynamically construct translation paths
   const basePath = nodePhotoBlogPost?.path?.alias || "";
   const translationPaths = {
      en: `/en${basePath}`, // English path
      es: `/es${basePath}`, // Spanish path
      ca: `${basePath}`, // Catalan path (default)
   };
    return(
      <div>
          <Layout translationPaths={translationPaths}>
            <Header/>
            <Title title={nodePhotoBlogPost?.title} color="bordeaux" type={1} className="size-56-line-64 d-flex justify-content-center space3" />
            <Footer/>
          </Layout>
      </div>
  )
}

export const pageQuery = graphql`
query PagePhotoGallery ($drupal_internal__nid: Int, $langcode: String!) {
  nodePhotoBlogPost (
        drupal_internal__nid: {eq: $drupal_internal__nid},
        langcode:{eq: $langcode}
        ){
          title
          created
          path{
            alias
          }
          relationships {
            field_image {
              uri {
                url
              }
            }
            field_video {
              uri {
                url
              }
            }
            field_category {
              name
            }
            field_tag {
              name
            }
          }
        }
}
`

export default PagePhotoGallery
