import React, { createContext } from 'react'
import { translate } from '../translate'
import PropTypes from 'prop-types'

const languageContextDefaultValue = {
  langcode: 'ca',
  translationPaths: {
    en: '/en',
    es: '/es',
    ca: '/'
  },
}

export const LanguageContext = createContext(languageContextDefaultValue)

const LanguageContextProvider = ({
  children,
  langcode,
  translationPaths,
}) => {

  //console.log('LanguageContextProvider:', { langcode, translationPaths });

  return (
    <LanguageContext.Provider
      value={{
        langcode,
        translationPaths,
        t: (text) => translate(langcode, text)
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

LanguageContextProvider.propTypes = {
  langcode: PropTypes.string
}

LanguageContextProvider.defaultProps = {
  langcode: `ca`
}

export default LanguageContextProvider
