const catalan = require('../translations/ca.json')
const spanish = require('../translations/es.json')
const english = require('../translations/en.json')

const translations = {
  es: spanish,
  en: english,
  ca: catalan
}

module.exports.translate = (langcode, defaultLanguageText) => {
  const translationText = translations[langcode]?.[defaultLanguageText]
  return translationText ?? defaultLanguageText
}

/**
 * You should create the home page from
 * nodes in Drupal.
 *
 * We are using a hard-coded home page here
 * to simplify the example.
 */
module.exports.homePagePaths = { 'ca': '/', 'es': '/es', 'en': '/en' }


/**
 * Get the current month (first day of the month and last day of the month)
 * @type {Date}
 */
const now = new Date();
const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

module.exports.currentMonth = {'field_start_time' : firstDay, 'field_end_time': lastDay}



/**
 * Get the current week (next sunday)
 * @type {Date}
 */
function getSundayOfCurrentWeek() {
   /*const today = new Date();
   const first = today.getDate() - today.getDay() + 1;
   const last = first + 6;
   const sunday = new Date(today.setDate(last));
   return sunday;*/
   // Get today's date
    const today = new Date();

    // Get the day of the week (0-6, where 0 is Sunday)
    const dayOfWeek = today.getDay();

    // Calculate the number of days until the next Sunday
    const daysUntilSunday = (7 - dayOfWeek) % 7;

    // If today is Sunday, return today's date
    if (daysUntilSunday === 0) {
      return today;
    }

    // Otherwise, add the number of days until the next Sunday to today's date
    const nextSunday = new Date(today.getTime() + daysUntilSunday * 24 * 60 * 60 * 1000);



    // Return the next Sunday
    return nextSunday;
}
module.exports.nextSunday = getSundayOfCurrentWeek()
