import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import OurHistory from "../components/our-history"
import Banner from "../components/banner"

const prefixAliasWithLanguage = (alias, langcode = 'ca') => (
    langcode === 'ca'
    ? `/author/${alias}`
    : `/${langcode}/author/${alias}`
  )


const PageHistory = ({
  data,
  location,
  pageContext,
}) => {
  const nodePage = data.nodePage
  const allNodeHistory = data.allNodeHistory.edges

  // Dynamically construct translation paths
  const basePath = nodePage?.path?.alias || "";
  const translationPaths = {
    en: `/en${basePath}`, // English path
    es: `/es${basePath}`, // Spanish path
    ca: `${basePath}`, // Catalan path (default)
    //default: `${basePath}`, // x-default
  };

  return(
        <Layout
          translationPaths={translationPaths}
          location={location}
          langcode={nodePage?.langcode}
        >
        <Seo
            title={nodePage?.title}
            lang={nodePage?.langcode}
            description={nodePage?.body?.summary}
            image={nodePage?.relationships?.field_image?.image_style_uri?._653_432}
            canonicalPath={basePath}
          />
          <Header
            lang={pageContext?.langcode}
            location={location}
          />
          <Banner
            post={nodePage}
            height={`480px`}
            backgroundColor="green"
          />
          <Title
            title={`Cronologia`}
            color="bordeaux"
            type={2}
            className="small-size-22 size-56-line-64 d-flex justify-content-center p-t-80 small-p-t-25 small-m-b-12 medium-p-t-56 medium-m-b-16 m-b-58"
          />
          <OurHistory
            nodes={allNodeHistory}
           />
          <Footer/>
        </Layout>
)
}

export const pageQuery = graphql`
query PageHistory ($drupal_internal__nid: Int, $langcode: String!) {
    nodePage (
      drupal_internal__nid: {eq: $drupal_internal__nid},
      langcode:{eq: $langcode}
      ){
        title
        path{
          alias
        }
        body {
          value
          summary
        }
        relationships{
          field_image{
            image_style_uri {
              _120x120
              _1440x408
              _294x192
              _541x359
              _653_432
              _1103x488
              large
              medium
              thumbnail
              wide
            }
          }
        }
      }
      allNodeHistory (
        filter: {langcode: {eq: $langcode}}
        sort: {fields: [field_date_year, field_date_month, field_date_day], order: [DESC, DESC, DESC]}
      )  {
        edges{
          node{
            field_weight
            title
            id
            langcode
        body {
          processed
          summary
          value
        }
        relationships {
          field_image {
            image_style_uri {
              _120x120
              _1440x408
              _294x192
              _541x359
              _653_432
              _1103x488
              large
              medium
              thumbnail
              wide
            }
          }
        }
        field_date_day
        field_date_month
        field_date_year
        field_cta {
          title
          url
        }
      }
          }
        }
    }
`
export default PageHistory
