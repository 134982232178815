import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import TaxonomyPage from "../components/pages/blog-page"
import useJsSearch from "../utils/useJsSearch"



const PageAuthor = ({ data, location, pageContext }) => {

  const post = data?.nodePage
  post.post_type = 'user'
  const english = data?.english
  const spanish = data?.spanish
  //const catalan = data?.catalan
  const blogPosts = post?.relationships?.node__blog_post
  const blogPostsSorted = blogPosts?.sort((a, b) => new Date(b.createdSort) - new Date(a.createdSort) );
  const categories = data?.categories
  const pageInfo = data?.blogPosts?.pageInfo //blogPosts.pageInfo

  // Dynamically construct translation paths
  const basePath = `/author/${post?.name?.replace(/\s+/g, '-').toLowerCase()}`;
  const translationPaths = {
    en: `/en${basePath}`, // English path
    es: `/es${basePath}`, // Spanish path
    ca: `${basePath}`, // Catalan path (default)
  };

  //const { search } = useJsSearch(blogPosts);

  //const [blogs, setBlogs] = useState(post?.relationships?.node__blog_post);
  const [searched, setSearched] = useState(false);
  const [initialQuery, setInitialQuery] = useState("");
  const [clearInput, setClearInput] = useState(false);
  //const [searchParams, setSearchParams] = useSearchParams();

  // Handles query state and prevents unnecessary rerendering
  /*useEffect(() => {
    const params = new URLSearchParams(location.search.slice(1));
    const q = clearInput ? params.delete("s") : params.get("s") ?? "";
    // Check if we have searched
    if (q !== initialQuery) {
      setSearched(false);
    }
    setInitialQuery(q);
    // If no query, reset blogs
    if (!q) {
      setBlogs(post?.relationships?.node__blog_post);
      return;
    }
    // If query exists and we haven't searched yet, execute search
    if (q && !searched) {
      const results = search(q);
      setBlogs(results);
      setSearched(true);
    }
  }, [
    searched,
    post?.relationships?.node__blog_post,
    search,
    location.search,
    initialQuery,
    clearInput
  ]);*/

  const refreshList = () => {
      setInitialQuery("")
      setClearInput(true)
      setSearched(false)
  }


  return (
    <Layout
      location={location}
      langcode={post?.langcode}
      translationPaths={translationPaths}
    >
    <Seo
        title={post?.title}
        lang={post?.langcode}
        description={post?.body?.summary}
        image={post?.relationships?.field_image?.image_style_uri?._653_432}
        canonicalPath={basePath}
      />
      <Header
        lang={pageContext?.langcode}
        location={location}
       />
        <TaxonomyPage
          post={post}
          lang={post?.langcode}
          blogPosts={blogPosts}
          bannerHeight="408px"
          categories={categories}
          pageInfo={pageInfo}
          initialQuery={initialQuery}
          refreshList={refreshList}
          isCategory={true}
          summaryAsBody={true}
         />
      <Footer
        lang={pageContext?.langcode}
        location={location}
      />
    </Layout>
  )
}


export const pageQuery = graphql`
query PageAuthor ($drupal_internal__uid: Int, $langcode: String!) {
    nodePage : userUser (
      drupal_internal__uid: {eq: $drupal_internal__uid},
      langcode: {eq: $langcode}
      ){
    name
    drupal_internal__uid
    title: field_name
    field_surname
    field_tagline
    langcode
    status
    relationships {
      node__blog_post {
        langcode
        title
        created(formatString: "DD MMMM YYYY", locale: $langcode)
        createdSort: created
        body {
          summary
          value
        }
        path {
          langcode
          alias
        }
        featureadImage {
          url
        }
        relationships {
          field_category {
            name
            path {
              alias
            }
            langcode
          }
          field_image {
            image_style_uri {
              large
              medium
              thumbnail
              wide
              _294x192
              _541x359
              _653_432
            }
          }
        }
      }
    }
  }
      categories: allTaxonomyTermCategory {
        edges {
          node {
            name
            drupal_id
            drupal_internal__tid
            path {
              alias
            }
          }
        }
      }


}
`

export default PageAuthor
