import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import Webform from '../../plugins/gatsby-drupal-webform-unida'
import CarePage from "../components/pages/care-page"

const PageHereForYou = ({ data, location, pageContext }) => {

  const post = data?.nodePage
  const english = data?.english
  const spanish = data?.spanish
  //const catalan = data?.catalan
  const events = data?.event
  const ministries = data?.ministries
  const promoted = data?.promoted
  const toknowus = data?.toknowus

  // Dynamically construct translation paths
  const basePath = post?.path?.alias || "";
  const translationPaths = {
    en: `/en${basePath}`, // English path
    es: `/es${basePath}`, // Spanish path
    ca: `${basePath}`, // Catalan path (default)
    //default: `${basePath}`, // x-default
  };

  return (
    <Layout
      location={location}
      langcode={post.langcode}
      translationPaths={translationPaths}
    >
    <Seo
        title={post.title}
        lang={post.langcode}
        description={post.body.summary}
        image={post?.relationships?.field_image?.image_style_uri?._653_432}
        canonicalPath={basePath}
      />
      <Header
        lang={pageContext?.langcode}
        location={location}
       />
       {pageContext?.type === 'here for you' &&
        <CarePage
          post={post}
          lang={post.langcode}
          events={events}
          bannerHeight="420px"
          ministries={ministries}
          promoted={promoted}
         />
       }
      <Footer
        lang={pageContext?.langcode}
      />
    </Layout>
  )
}

const ContactForm = ({ data }) => {
  return(
      <Webform
        webform={data}
        endpoint={`${process.env.GATSBY_DRUPAL_API_URL}/react_webform_backend/submit`} // "http://localhost:8888/react_webform_backend/submit"
        onSuccess={(response) => navigate(response.settings.confirmation_url)}
      />
  )
  }

export const pageQuery = graphql`
query PageHereForYou ($drupal_internal__nid: Int, $langcode: String!) {
      nodePage(
        drupal_internal__nid: {eq: $drupal_internal__nid},
        langcode:{eq: $langcode}
        ) {
        langcode
        drupal_internal__nid
        id
        title
        drupal_id
        body {
          value
          summary
        }
        path {
          alias
        }
        created
        relationships {
          field_image {
            image_style_uri {
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
        }
      }
        ministries: allNodeSubministry(
          filter: {
              field_show_on_here_for_you: {eq: "yes"},
              langcode: {eq: $langcode}
           }
        ) {
          edges {
            node {
              title
              field_form {
                drupal_internal__target_id
              }
              field_image {
                title
              }
              path {
                alias
              }
              body {
                processed
                summary
                value
              }
              relationships {
                field_form {
                  elements {
                    name
                    options {
                      label
                      value
                    }
                    type
                    attributes {
                      name
                      value
                    }
                  }
                  title
                }
                field_image {
                  image_style_uri {
                    _294x192
                    _653_432
                    _541x359
                    large
                    medium
                    thumbnail
                    wide
                  }
                }
              }
            }
          }
        }
        allNodeSubministry {
            edges {
              node {
                relationships {
                  field_ministry {
                    title
                  }
                  field_image {
                    uri {
                      url
                    }
                  }
                }
                title
                body {
                  processed
                }
                field_weight
              }
            }
          }
          form: webformWebform(drupal_internal__id: {eq: "contact"}) {
            drupal_internal__id
            langcode
            elements {
              name
              type
              attributes {
                name
                value
              }
            }
          }
}
`

export default PageHereForYou
