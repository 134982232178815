import React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"

const PageDevotionals = ({data}) => {
  const nodePage = data.nodePage
  const nodeDevotionals = data.nodeDevotionals
  // Dynamically construct translation paths
  const basePath = nodePage?.path?.alias || "";
  const translationPaths = {
    en: `/en${basePath}`, // English path
    es: `/es${basePath}`, // Spanish path
    ca: `${basePath}`, // Catalan path (default)
    default: `${basePath}`, // x-default
  };

  return(
    <div>
      <Layout translationPaths={translationPaths}>
        <Header/>
          <Title title={nodePage?.title} color="bordeaux" type={1} className="size-56-line-64 d-flex justify-content-center space3" />
          <p>{nodeDevotionals?.field_devotional_title}</p>
        <Footer/>
      </Layout>
    </div>
)
}

export const pageQuery = graphql`
query PageDevotionals ($drupal_internal__nid: Int, $langcode: String!) {
    nodePage (drupal_internal__nid: {eq: $drupal_internal__nid}, langcode:{eq: $langcode}){
          title
          path{
            alias
          }
          body {
            processed
          }
          relationships{
            field_image{
              uri{
                url
              }
            }
          }
        } 

      nodeDevotionals (langcode: {eq: $langcode}) {
            field_devotional_title
            field_devotional_subtitle
            relationships {
              field_devotional_image {
                uri {
                  url
                }
              }
              field_groups_image {
                uri {
                  url
                }
              }
            }
            field_groups_subtitle
            field_groups_title
          }
    }
`

export default PageDevotionals