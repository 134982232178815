import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import WatchPage from "../components/pages/watch-page"
import CarePage from "../components/pages/care-page"
import ConnectPage from "../components/pages/connect-page"
import MinistriesPage from "../components/pages/ministries-page"


const Page =  ({ data, location, pageContext }) => {

  const post = data?.nodePage
  const english = data?.english
  const spanish = data?.spanish
  //const catalan = data?.catalan
  const events = data?.event
  const ministries = data?.ministries
  const mainMinistries  = data?.mainMinistries
  const promoted = data?.promoted
  const toknowus = data?.toknowus
  //const localSearchPages = data?.localSearchPages
  const services = data?.services

  // Dynamically construct translation paths
   const basePath = post?.path?.alias || "";
   const translationPaths = {
      en: `/en${basePath}`, // English path
      es: `/es${basePath}`, // Spanish path
      ca: `${basePath}`, // Catalan path (default)
   };

  return (
    <Layout
      location={location}
      langcode={post.langcode}
      translationPaths={translationPaths}
    >
    <Seo
        title={post.title}
        lang={post.langcode}
        description={post.body.summary}
        image={post?.relationships?.field_image?.image_style_uri?._653_432}
        canonicalPath={basePath}
      />
      <Header
        lang={pageContext?.langcode}
        location={location}
       />
       {pageContext?.type === 'watch' &&
        <WatchPage
          post={post}
          lang={post.langcode}
          events={events}
         />
       }
       {pageContext?.type === 'here for you' &&
        <CarePage
          post={post}
          lang={post.langcode}
          events={events}
          bannerHeight="420px"
          ministries={ministries}
          promoted={promoted}
         />
       }
       {pageContext?.type === 'welcome' &&
        <ConnectPage
          post={post}
          lang={post.langcode}
          events={events}
          bannerHeight="420px"
          ministries={ministries}
          promoted={promoted}
          buttonBanner="Vull saber més"
          toknowus={toknowus}
         />
       }
       {pageContext?.type === 'ministries' &&
        <MinistriesPage
          post={post}
          lang={post.langcode}
          events={events}
          bannerHeight="512px"
          ministries={mainMinistries}
          promoted={promoted}
          buttonBanner="Comença a servir"
          toknowus={toknowus}
          //localSearchPages={localSearchPages}
          pageContext={pageContext}
          services={services}
         />
       }
      <Footer
        lang={pageContext?.langcode}
      />
    </Layout>
  )
}

export default Page;


export const query = graphql`
  query($drupal_internal__nid: Int, $langcode: String!) {
    nodePage(
      drupal_internal__nid: {eq: $drupal_internal__nid},
      langcode:{eq: $langcode}
      ) {
      langcode
      drupal_internal__nid
      id
      title
      path{
        alias
      }
      drupal_id
      body {
        value
        summary
      }
      created
      relationships {
        field_image {
          image_style_uri {
            _294x192
            _541x359
            _653_432
            large
            medium
            thumbnail
            wide
          }
        }
      }
    }
    catalan: nodePage(
      drupal_internal__nid: { eq: $drupal_internal__nid },
      langcode: { eq: "ca" }
    ) {
      path {
        alias
      }
    }
    english: nodePage(
      drupal_internal__nid: { eq: $drupal_internal__nid },
      langcode: { eq: "en" }
    ) {
      path {
        alias
      }
    }
    spanish: nodePage(
      drupal_internal__nid: { eq: $drupal_internal__nid },
      langcode: { eq: "es" }
    ) {
      path {
        alias
      }
    }
    event:nodeEvent(
      nextSunday: {eq: true}
      langcode: { eq: $langcode }
      field_event_type: {eq: "Worship"}
    ) {
      field_url_online_event {
        title
        uri
      }
      title
      relationships {
        field_youtube_image {
          image_style_uri {
            large
            medium
            sponsors
            thumbnail
            wide
          }
        }
      }
    }
    ministries: allNodeSubministry(
      filter: {
          field_show_on_here_for_you: {eq: "yes"},
          langcode: {eq: $langcode}
       }
    ) {
      edges {
        node {
          title
          field_form {
            drupal_internal__target_id
          }
          field_image {
            title
          }
          path {
            alias
          }
          body {
            processed
            summary
            value
          }
          relationships {
            field_form {
              elements {
                name
                options {
                  label
                  value
                }
                type
                attributes {
                  name
                  value
                }
              }
              title
            }
            field_image {
              image_style_uri {
                _294x192
                _653_432
                _541x359
                large
                medium
                thumbnail
                wide
              }
            }
          }
        }
      }
    }
    mainMinistries: allNodeMinistry(
      filter: {
          langcode: {eq: $langcode}
       }
    ) {
      edges {
        node {
          title
          field_image {
            title
          }
          path {
            alias
          }
          body {
            processed
            summary
            value
          }
          relationships {
            subministries: node__subministry {
              title
              field_show_on_here_for_you
            }
            field_image {
              image_style_uri {
                _294x192
                _653_432
                _541x359
                large
                medium
                thumbnail
                wide
              }
            }
          }
        }
      }
    }
    toknowus: nodeWelcome {
      field_connect_body {
        processed
        value
      }
      field_connect_cta {
        uri
        url
        title
      }
      field_connect_related_body_1 {
        processed
        value
      }
      field_connect_related_body_2 {
        processed
        value
      }
      field_connect_related_body_3 {
        processed
        value
      }
      field_connect_related_body_4 {
        processed
        value
      }
      field_connect_related_cta_1 {
        title
        uri
        url
      }
      field_connect_related_cta_2 {
        title
        uri
        url
      }
      field_connect_related_cta_3 {
        title
        uri
        url
      }
      field_connect_related_cta_4 {
        title
        uri
        url
      }
      field_connect_related_title_1
      field_connect_related_title_2
      field_connect_related_title_3
      field_connect_related_title_4
      field_connect_title
      field_field_meet_body_1 {
        processed
        value
      }
      field_meet_body_2 {
        processed
        value
      }
      field_mark_difference_body {
        processed
        value
      }
      field_mark_difference_title
      field_markdif_related_title_1
      field_markdif_related_title_2
      field_markdif_related_body_1 {
        processed
        value
      }
      field_markdif_related_body_2 {
        processed
        value
      }
      field_markdif_related_cta_1 {
        title
        uri
        url
      }
      field_markdif_related_cta_2 {
        title
        uri
        url
      }
      field_meet_body_2 {
        processed
        value
      }
      field_meet_cta_1 {
        title
        uri
        url
      }
      field_meet_cta_2 {
        title
        uri
        url
      }
      field_visit_body_3 {
        processed
        value
      }
      field_visit_body_1 {
        processed
        value
      }
      field_visit_body_2 {
        processed
        value
      }
      field_visit_body_4 {
        processed
        value
      }
      field_visit_cta_1 {
        title
        uri
        url
      }
      field_visit_cta_2 {
        title
        uri
        url
      }
      field_visit_cta_3 {
        title
        url
        uri
      }
      field_visit_cta_4 {
        title
        uri
        url
      }
      field_visit_social_media_body {
        processed
        value
      }
      field_visit_title
      relationships {
        field_connect_related_image_1 {
          image_style_uri {
            _120x120
            _1440x408
            _294x192
            _541x359
            _653_432
            large
            medium
            thumbnail
            wide
          }
        }
        field_connect_related_image_2 {
          image_style_uri {
            _120x120
            _1440x408
            _294x192
            _541x359
            _653_432
            large
            medium
            thumbnail
            wide
          }
        }
        field_connect_related_image_3 {
          image_style_uri {
            _120x120
            _294x192
            _1440x408
            _541x359
            _653_432
            large
            medium
            thumbnail
            wide
          }
        }
        field_connect_related_image_4 {
          image_style_uri {
            _120x120
            _294x192
            _1440x408
            _541x359
            _653_432
            large
            medium
            thumbnail
            wide
          }
        }
        field_markdif_related_image_1 {
          image_style_uri {
            _120x120
            _1440x408
            _541x359
            _294x192
            _653_432
            large
            medium
            thumbnail
            wide
          }
        }
        field_markdif_related_image_2 {
          image_style_uri {
            _120x120
            _1440x408
            _294x192
            _541x359
            _653_432
            large
            medium
            thumbnail
            wide
          }
        }
        field_meet_image_1 {
          image_style_uri {
            _120x120
            _1440x408
            _294x192
            _541x359
            _653_432
            medium
            large
            thumbnail
            wide
          }
        }
        field_meet_image_2 {
          image_style_uri {
            _120x120
            _1440x408
            _294x192
            _541x359
            _653_432
            large
            medium
            thumbnail
            wide
          }
        }
        field_visit_image_1 {
          image_style_uri {
            _120x120
            _1440x408
            _541x359
            _653_432
            _294x192
            large
            medium
            thumbnail
            wide
          }
        }
        field_visit_image_2 {
          image_style_uri {
            _120x120
            _1440x408
            _294x192
            _541x359
            _653_432
            large
            medium
            thumbnail
            wide
          }
        }
        field_visit_image_3 {
          image_style_uri {
            _120x120
            _1440x408
            _294x192
            _541x359
            _653_432
            large
            medium
            thumbnail
            wide
          }
        }
        field_visit_image_4 {
          image_style_uri {
            _120x120
            _1440x408
            _294x192
            _541x359
            _653_432
            large
            medium
            thumbnail
            wide
          }
        }
      }
    }
    promoted: nodeSubministry(
      field_show_on_here_for_you: {eq: "promoted"},
      langcode: { eq: $langcode }
     ) {
      field_show_on_here_for_you
      langcode
      path {
        alias
      }
      title
      field_form {
        drupal_internal__target_id
      }
      relationships {
        field_form {
          elements {
            name
            options {
              label
              value
            }
            type
            attributes {
              name
              value
            }
          }
          title
        }
        field_image {
          image_style_uri {
            _294x192
            _541x359
            _653_432
            large
            medium
            thumbnail
            wide
          }
        }
      }
      body {
        summary
        value
      }
    }
    services: allNodeServices(
      filter: {
        status: {eq: true},
        langcode: {eq: $langcode}
        field_need_volunteers: {eq: true}
      }
    ) {
      edges {
        node {
          title
          field_when
          field_minimum
          path {
            alias
          }
          sticky
          body {
            summary
            value
          }
          relationships {
            field_ministry {
              title
            }
          }
        }
      }
    }
  }
`
