import React, { useContext } from "react"
import {Link, graphql, navigate } from "gatsby"
import { homePagePaths, translate } from '../translate'
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import Section from "../components/section"
import { LanguageContext } from "../context"
import Banner from "../components/banner"
import SectionContent from "../components/section/section-full-with-image"
import Utils from "../utils"
import Ribbon from "../components/ribbon"
import ButtonModal from "../components/button-visit-us"


const PageWorship = ({ data, location, pageContext }) => {
    const post = data.nodePage
    const nodeWorship = data.nodeWorship
    const preachSeries = data?.preachSeries?.edges
    const { t } = useContext(LanguageContext)
    // Dynamically construct translation paths
    const basePath = post?.path?.alias || "";
    const translationPaths = {
      en: `/en${basePath}`, // English path
      es: `/es${basePath}`, // Spanish path
      ca: `${basePath}`, // Catalan path (default)
    };

    return(
      <div>
          <Layout
            translationPaths={translationPaths}
            location={location}
            langcode={post?.langcode}
            className="page-worship"
          >
          <Seo
              title={post?.title}
              lang={post?.langcode}
              description={post?.body?.summary}
              image={post?.relationships?.field_image?.image_style_uri?._653_432}
              canonicalPath={basePath}
            />
          <Header
            lang={pageContext?.langcode}
            location={location}
           />
           <Banner
             className="banner-what-is-worship"
             post={post}
             height={"512px"}
             backgroundColor="green"
             hasButton
             multipleButtons={[
               <ButtonModal
                title="Visita'ns"
                className="btn btn-outline btn-outline-white my-2 my-sm-0"
                backgroundimage={nodeWorship?.field_image?.image_style_uri?.wide}
                />
             ]}
             backgroundimage={nodeWorship?.field_image?.image_style_uri?.wide}
           />
           <Ribbon
             lang={pageContext?.langcode}
             preachSeries={preachSeries}
             className="worship-page medium-m-b-74"
            />
           <Section
            key={1}
            className="section-what-is-worship background-white p-t-80- small-p-t-24"
            id="section-what-is-worship"
            title={nodeWorship?.title}
            headingClassName="H2-Medium-C---bordeaux medium-p-b-0 small-p-b-0 m-b-32 medium-m-b-74 small-m-b-16 p-b-88"
            subHeadingClassName="H5-Medium-C---black max-877-center-content p-b-88 medium-p-l-r-24"
            t={t}
            >
            <SectionContent
              body={nodeWorship?.body?.value}
              image={nodeWorship?.relationships?.field_image?.image_style_uri?._653_432}
              imagePos="left"
              color="bordeaux"
              ctas={[
                <Link
                  to={nodeWorship?.field_cta?.url}
                  title={nodeWorship?.field_cta?.title}
                  className="btn btn-outline btn-outline-visit-us bordeaux"
                >
                {nodeWorship?.field_cta?.title}
                </Link>
              ]}
            />
            </Section>

            <Section
             key={2}
             className="section-our-actions background-white p-t-80- small-p-t-24 p-b-88 small-p-b-40"
             id="section-our-actions"
             title={nodeWorship?.field_actions_title}
             headingClassName="H2-Medium-C---green-dark medium-p-b-0 small-p-b-0 m-b-32 medium-m-b-74 small-m-b-16 p-b-88"
             //subHeadingClassName="H5-Medium-C---black max-877-center-content p-b-88 medium-p-l-r-24"
             t={t}
             >
             <SectionContent
               body={nodeWorship?.field_actions_body?.value}
               image={nodeWorship?.relationships?.field_actions_image?.image_style_uri?._653_432}
               imagePos="right"
               color="green-dark"
             />
             </Section>

             <Section
              key={3}
              className="section-watch-live background-beig p-t-80- small-p-t-24 p-b-88"
              id="section-watch-live"
              title={nodeWorship?.field_watch_cult_title}
              headingClassName="H2-Medium-C---bordeaux medium-p-b-0 small-p-b-0 m-b-32 medium-m-b-16 small-m-b-16 p-t-80 container small-p-t-24"
              //subHeadingClassName="H5-Medium-C---black max-877-center-content p-b-88 medium-p-l-r-24"
              t={t}
              >
              <SectionContent
                body={nodeWorship?.field_watch_cult_body?.value}
                //image={nodeWorship?.relationships?.field_actions_image?.image_style_uri?._653_432}
                imagePos="center"
                color="bordeaux"
                video={nodeWorship?.field_watch_video_url?.uri}
                ctas={[
                  <ButtonModal
                   title="Visita'ns"
                   className="btn btn-outline btn-outline-visit-us m-b-24"
                   backgroundimage={nodeWorship?.field_image?.image_style_uri?.wide}
                   />
                ]}
              />
              </Section>

            <Footer
              lang={pageContext?.langcode}
              location={location}
             />
          </Layout>
      </div>
  )
}

export const pageQuery = graphql`
query PageWorship ($drupal_internal__nid: Int, $langcode: String!){
    nodePage (
        drupal_internal__nid: {eq: $drupal_internal__nid},
        langcode:{eq: $langcode}
        ){
          title
          path{
            alias
          }
          body {
            value
          }
          relationships{
            field_image{
                image_style_uri{
                  wide
                  _653_432
                }
            }
          }
        }
        nodeWorship (langcode:{eq: $langcode}){
            title
            body {
              value
            }
            relationships {
              field_image {
                image_style_uri {
                  _653_432
                }
              }
              field_actions_image {
                image_style_uri {
                  _653_432
                }
              }
              field_watch_cult_video {
                uri {
                  url
                }
              }
            }
            field_cta {
              title
              url
            }
            field_actions_title
            field_actions_body {
              value
            }
            field_watch_cult_cta {
              title
              url
            }
            field_watch_cult_body {
              value
            }
            field_watch_cult_title
            field_watch_video_url {
              title
              uri
            }
          }
          preachSeries: allNodeEvent(
            filter: {
              field_event_type: {eq: "Worship"},
              nextSunday: {eq: true}}
          ) {
            edges {
              node {
                field_series_session_number
                field_event_type
                title
                field_serie {
                  drupal_internal__target_id
                }
                relationships {
                  field_serie {
                    field_serie_subtitle
                    field_total_sessions
                    name
                  }
                }
                nextSunday
                field_start_time
              }
            }
          }
}

`

export default PageWorship
