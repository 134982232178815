import React, { useContext } from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { homePagePaths, translate } from '../translate'
import Header from "../components/header"
import Footer from "../components/footer"
import Title from "../components/title"
import { LanguageContext } from "../context"
import Banner from "../components/banner"
import Section from "../components/section"
import SectionContent from "../components/section/section-boxed-width-image"
import Utils from "../utils"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

const PageFaqs = ({ data, location, pageContext }) => {

    const post = data.nodePage
    const faqs = data?.allNodeFaqs
    const { t } = useContext(LanguageContext)
    const breakpoints = useBreakpoint()

  // Dynamically construct translation paths
  const basePath = post?.path?.alias || "";
  const translationPaths = {
    en: `/en${basePath}`, // English path
    es: `/es${basePath}`, // Spanish path
    ca: `${basePath}`, // Catalan path (default)
    default: `${basePath}`, // x-default
  };


    return (
        <div>
          <Layout
            translationPaths={translationPaths}
            location={location}
            langcode={post?.langcode}
            className="page-faqs"
            >
            <Seo
                title={post?.title}
                lang={post?.langcode}
                description={post?.body?.summary}
                image={post?.relationships?.field_image?.image_style_uri?._653_432}
                canonicalPath={basePath}
              />
              <Header
                lang={pageContext?.langcode}
                location={location}
               />
               <Banner
                 post={post}
                 height={"408px"}
                 backgroundColor="green"
               />
               <Title
                  type={2}
                  title="FAQ’s"
                  color="bordeaux"
                  weight="500"
                  className="H2-Medium-C---bordeaux p-t-80 medium-p-t-52 medium-p-b-74- small-p-t-24 small-p-b-14-"
               />
               <Section
                key={1}
                className="section-faqs background-white p-t-80 medium-p-t-74 small-p-t-24"
                id="section-faqs"
                title={faqs?.field_mission_title}
                subtitle={faqs?.field_mission_subtitle}
                headingClassName="H2-Medium-C---bordeaux medium-p-b-0 small-p-b-0 m-b-32 medium-m-b-16 small-m-b-16"
                subHeadingClassName="H5-Medium-C---black max-877-center-content p-b-88 medium-p-l-r-24"
                t={t}
                >
                <div className="container medium-p-0">
                  <div className="row small-p-l-16">
                  {faqs && faqs?.edges?.length && faqs.edges.map(({node}, index) => {
                    return(
                      <SectionContent
                        body={node?.body?.value}
                        image={node?.relationships?.field_image?.image_style_uri?._653_432}
                        imagePos="left"
                        color="bordeaux"
                        cta={node?.field_cta}
                        className={`small-0 p-0 ${breakpoints.md ? 'row' : 'col-lg-6' } item ${index%2 !== 0 ? 'odd' : 'even' } ${index == 0 ? 'first-item' : '' }`}
                        title={node?.title}
                      />
                    )
                  })}
                  </div>
                </div>
                </Section>
            <Footer />
          </Layout>
        </div>
    )
}

export const pageQuery = graphql`
query PageFaqs ($drupal_internal__nid: Int, $langcode: String!) {
    nodePage (
      drupal_internal__nid: {eq: $drupal_internal__nid},
      langcode:{eq: $langcode}
      ){
        title
        path{
          alias
        }
        body {
          value
        }
        relationships{
          field_image{
            image_style_uri {
              _294x192
              _541x359
              _653_432
              large
              medium
              thumbnail
              wide
            }
          }
        }
      }
      allNodeFaqs(
        filter: {langcode: {eq: $langcode}}
        sort: {order: ASC, fields: field_weight}
      ) {
        edges {
          node {
            title
            body {
              value
            }
            relationships {
              field_image {
                image_style_uri {
                  wide
                  _653_432
                }
              }
            }
            field_cta {
              title
              uri
              url
            }
            field_weight
          }
        }
      }
}
`
export default PageFaqs
